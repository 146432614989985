import { createRouter, createWebHistory } from "vue-router";
//createWebHashHistory,
import HomeView from "../views/HomeView.vue";
import AuthLogin from "../components/AuthLogin.vue";
import SignUp from "../components/SignUp.vue";
//import { createClient } from "@supabase/supabase-js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    // meta: { requiresAuth: true },
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
  },
  {
    path: "/login",
    name: "login",
    component: AuthLogin,
  },
  {
    path: "/voucher_details",
    name: "voucher",
    //meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VoucherDetails.vue"),
  },
  {
    path: "/voucher",
    name: "generate_voucher",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RenderPdf.vue"),
  },
  {
    path: "/signature",
    name: "generate_signature",
    //meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateSignature.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/*  test  */

// Supabase initialization
// const supabase = createClient(
//   process.env.VUE_APP_SUPABASE_URL,
//   process.env.VUE_APP_SUPABASE_ANON_KEY
// );
//
// router.beforeEach(async (to) => {
//   // check if the route requires authentication
//   const session = await supabase.auth.getSession();
//   if (to.meta.requiresAuth && session.value !== null) {
//     return "/login";
//   } else {
//     return "/";
//   }
// });
// check if the user is authenticated
//   const { user, error } = await supabase.auth.getUser();
//   console.log(user);
//   //console.log("tessssssssssssssssssssssssssssss" + user);
//   if (error || !user) {
//     // redirect unauthenticated users to the login page
//     next("/login");
//   } else {
//     // allow authenticated users to access the route
//     if (to.path === "/login") {
//       next("/"); // redirect to the dashboard page if already authenticated
//     } else {
//       next(); // allow access to the requested route
//     }
//   }
// } else {
//   // allow all other routes to be accessed without authentication
//});

export default router;
