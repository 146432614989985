<template>
  <div>&nbsp;</div>
  <div class="container">
    <n-card hoverable class="left-card">
      <div class="left">
        <table class="instructions-table">
          <h2>Leitfaden</h2>
          <tbody>
            <tr>
              <td class="number circle">1</td>
            </tr>
            <tr>
              <td class="instruction">
                Gehen Sie zu "Signatur bearbeiten" und zeichnen Sie Ihre
                Unterschrift im gestrichelten Bereich.
              </td>
            </tr>
            <tr>
              <td class="number circle">2</td>
            </tr>
            <tr>
              <td class="instruction">
                Gehen Sie zu "Bewirtungsbeleg eingeben" und geben Sie die
                Teilnehmer und die Daten des Bewirtungsbelegs ein.
              </td>
            </tr>
            <tr>
              <td class="number circle">3</td>
            </tr>
            <tr>
              <td class="instruction">
                Speichern Sie die Informationen aus dem Bewirtungsbeleg.
                Beachten Sie, dass Sie bei Bedarf alle Einträge bearbeiten
                können.
              </td>
            </tr>
            <tr>
              <td class="number circle">4</td>
            </tr>
            <tr>
              <td class="instruction">
                Laden Sie alle Restaurantrechnungen hoch, wenn Sie alle
                Bewirtungsbeleg-Informationen eingegeben haben.
              </td>
            </tr>
            <tr>
              <td class="number circle">5</td>
            </tr>
            <tr>
              <td class="instruction">
                Klicken Sie auf "Gutscheine erstellen" und es wird eine weitere
                Seite angezeigt, auf der Sie die Bewirtungsbelege anzeigen
                können.
              </td>
            </tr>
            <tr>
              <td class="number circle">6</td>
            </tr>
            <tr>
              <td class="instruction">
                Jetzt können Sie sie als PDF herunterladen.
              </td>
            </tr>
            <tr>
              <td class="number circle">7</td>
            </tr>
            <tr>
              <td class="instruction">
                Viel Spaß damit und Ihrem Steuerberater.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </n-card>
    <n-card hoverable class="right-card">
      <div class="right">
        <div class="logo-container">
          <img src="@/assets/vouchers.png" alt="Logo" class="logo" />
        </div>
        <div class="text-container">
          <h2 class="app-name">Bewirtungsbelege.io</h2>
          <p class="description">
            Bewirtungsbelege.io ist ein Tool, das für alle entwickelt wurde, die
            glauben, dass Zeit das kostbarste Gut ist, das wir haben. Mit diesem
            Tool möchte ich den Menschen Zeit sparen und lästige und repetitive
            Aufgaben erleichtern. Ich hoffe, dass es mit Ihrer Hilfe zu meinem
            ersten Beitrag wird.
          </p>
          <BuyCoffee />
        </div>
      </div>
    </n-card>
  </div>
  <div>&nbsp;</div>
</template>

<style>
.circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #40dca5;
  color: #fff;
  text-align: center;
  line-height: 24px;
}

.left-card {
  margin-right: 20px;
}

.right-card {
  margin-left: 20px;
}

.container {
  display: flex;
}

.left {
  flex: 1;
  padding: 10px;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instructions-table {
  width: 100%;
  border-collapse: collapse;
}

.number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #dcdcdc;
  color: #fff;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}

.instruction {
  padding-left: 10px;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.app-name {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.description {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}
</style>

<script>
import BuyCoffee from "@/components/BuyCoffee.vue";
import { NCard } from "naive-ui";

export default {
  components: {
    BuyCoffee,
    NCard,
  },
};
</script>
