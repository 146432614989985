<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" test="this is a test" />-->
    <WelcomeInstruction />
  </div>
  <footer class="footer">
    <div class="content has-text-centered">&nbsp;&nbsp;&nbsp;</div>
  </footer>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import WelcomeInstruction from "@/components/WelcomeInstructions.vue";
//import buyMeACoffeeWidget from "@/components/BuyCoffee.vue";

export default {
  name: "HomeView",
  components: {
    //HelloWorld,
    WelcomeInstruction,
    //buyMeACoffeeWidget,
  },
};
</script>
