<script setup>
import { createClient } from "@supabase/supabase-js";
import { NButton, NAlert } from "naive-ui";
import { ref, onMounted } from "vue";

// Supabase initialization
const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL,
  process.env.VUE_APP_SUPABASE_ANON_KEY
);

const loading = ref(false);
const isAuthorized = ref();
console.log(isAuthorized.value);

const getUserByEmail = async (email) => {
  const { data, error } = await supabase
    //.from("auth_users")
    //.select("username")
    //.eq("username", email)
    .from("users")
    .select("email")
    .eq("email", email)
    .single();
  if (error) {
    console.error(error);
    //const { data, error } = await supabase.auth.admin.deleteUser(
    //supabase.auth.signOut();
    return null;
  }
  console.log(data);
  //return data.username;
  if (data.length === 0) {
    return false;
  } else {
    return true;
  }
};

/*const gotoSignUp = () => {
  this.$router.push("/signup");
};*/

const signInWithGoogle = async () => {
  // first check if the user is in the users table
  console.log("signInWithGoogle called"); // Add this line
  loading.value = true;
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
  });
  if (error) {
    console.error(error);
    return;
  }
  console.log(data);
};

onMounted(() => {
  const authListener = async (event, session) => {
    //console.log("Auth state changed:", event, session);

    if (event === "SIGNED_IN") {
      // User signed in
      //console.log("SIGNED_IN", session);
      //console.log("now checking mail");
      const usr_em = session.user.email;
      //const usr_vr = session.user.user_metadata.email_verified;

      const usr = await getUserByEmail(usr_em);

      console.log("User email:", usr_em); // Log the user's email address
      console.log(usr);
      //console.log("User verified:", usr_vr);

      if (usr === true) {
        //const { user } = useAuthUser();
        console.log(usr_em, "User is authorized");
        isAuthorized.value = true;
        //user.value = session?.user || null;
        console.log(isAuthorized.value);
      } else {
        console.log("User is not authorized, kicking him out");
        localStorage.removeItem("session");
        isAuthorized.value = false;
        console.log(isAuthorized.value);
        supabase.auth.signOut();
      }
    } else if (event === "SIGNED_OUT") {
      localStorage.removeItem("session");
      isAuthorized.value = false;
      // User signed out
    }
  };
  supabase.auth.onAuthStateChange(authListener);
});
</script>

<template>
  <div class="login-page">
    <div class="left-section">
      <h2 class="intro-title">
        Müde, ständig lästige Büroarbeit von Hand zu erledigen?
      </h2>
      <p class="intro-text">
        Mit unserer App kannst du spielend leicht und völlig kostenlos
        gleichzeitig mehrere Bewirtungsbelege erstellen und dir so mehrere
        Stunden sinnloser Büroarbeit sparen – und das alles in nur 3 einfachen
        Schritten! <br />
        &nbsp; Bist du es leid, ständig mühsame Papierkram zu erledigen? Schluss
        mit dem ständigen Kramen in Aktenordnern und dem Ausfüllen von
        Formularen. Wir machen das jetzt kinderleicht und machen dir das Leben
        einfacher. Also worauf wartest du noch? Spare Zeit, spare Nerven und
        melde dich an – für eine papierlose Zukunft voller Leichtigkeit und
        Freiheit!
      </p>
    </div>
    <div class="right-section">
      <div class="login-container">
        <img alt="Vue logo" src="@/assets/vouchers.png" class="app-icon" />
        <h2 class="app-name">Bewirtungsbelege.io</h2>
        <n-alert
          title="Authorization Error"
          type="error"
          closable
          v-if="isAuthorized === false"
        >
          The user is not authorized! Please sign up first.
        </n-alert>
        <n-button
          class="login-button"
          type="primary"
          shape="round"
          :round="true"
          @click="signInWithGoogle"
          :loading="loading"
        >
          Log In with Google
        </n-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.left-section {
  width: 50%;
  padding: 40px;
}

.intro-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.intro-text {
  font-size: 16px;
  line-height: 1.5;
}

.right-section {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  width: 300px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.app-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.app-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  padding: 10px;
}
</style>
