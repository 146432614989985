<template>
  <AppProvider>
    <div v-if="session" :session="session">
      <nav>
        <router-link class="router" to="/">Home</router-link>
        <router-link class="router" to="/voucher_details"
          >Bewirtungsbelege</router-link
        >
        <router-link class="router" to="/signature"> Unterschrift</router-link>
        <n-button class="router" type="primary" round @click="LogOut">
          Logout</n-button
        >
      </nav>
      <router-view />
    </div>

    <AuthLogin v-else />
    <!--<SignUp />-->
  </AppProvider>
</template>

<script setup>
import { createClient } from "@supabase/supabase-js";
import { onMounted, ref } from "vue";
import AuthLogin from "./components/AuthLogin.vue";
import { NButton } from "naive-ui";
//import SignUp from "./components/SignUp.vue";

const session = ref();
// Supabase initialization
const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL,
  process.env.VUE_APP_SUPABASE_ANON_KEY
);

const LogOut = () => {
  supabase.auth.signOut();
  //router.push("/login");
};

onMounted(() => {
  supabase.auth.getSession().then(({ data }) => {
    session.value = data.session;
  });

  supabase.auth.onAuthStateChange((_, _session) => {
    session.value = _session;
  });
});
</script>

<script>
import { defineComponent } from "vue";
import AppProvider from "@/components/app-provider.vue";

export default defineComponent({
  name: "App",
  components: {
    AppProvider,
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.router {
  margin-right: 10px;
}
</style>
