<template>
  <n-message-provider>
    <div>
      <h1>Signup</h1>
      <n-space vertical>
        <n-input v-model:value="email" placeholder="Enter your email" />
        <h2>Verify your email</h2>
        <n-button type="primary" @click="signup">Signup</n-button>
      </n-space>

      <!--<n-form ref="signupForm" :model="form" :rules="signupRules">
        <n-form-item label="Email" path="email">
          <n-input v-model:value="form.email" placeholder="Enter your email" />
        </n-form-item>
        <n-form-item>
          <n-button type="primary" @click="signup">Signup</n-button>
        </n-form-item>
      </n-form>
      <HCaptcha
        ref="{captcha}"
        sitekey="2da1046c-b752-4c99-b181-4af3be7f7b29"
      />-->
    </div>
  </n-message-provider>
</template>

<script>
import { ref } from "vue";
import { useMessage } from "naive-ui";
import { createClient } from "@supabase/supabase-js";
//import { NForm, NFormItem, NInput, NButton } from "naive-ui";
import { NInput, NButton } from "naive-ui";
//import HCaptcha from "@hcaptcha/react-hcaptcha";

export default {
  components: {
    /*NForm,
    NFormItem,*/
    NButton,
    NInput,
  },
  setup() {
    const message = useMessage();
    //const captcha = useRef();
    //const [captchaToken, setCaptchaToken] = useState();
    const signupForm = ref(null);
    const form = ref({
      email: "",
    });
    const email = ref(null);

    const signupRules = {
      email: [
        { required: true, message: "Email is required", trigger: "blur" },
        { type: "email", message: "Invalid email format", trigger: "blur" },
        { validator: validateGmailAccount, trigger: "blur" },
      ],
    };

    const supabase = createClient(
      process.env.VUE_APP_SUPABASE_URL,
      process.env.VUE_APP_SUPABASE_ANON_KEY
    );

    async function signup() {
      try {
        await signupForm.value?.validate();

        // Insert the user record into the 'auth_users' table
        const { error } = await supabase
          .from("auth_users")
          .insert([{ username: email.value, status: "P" }]);

        if (error) {
          console.error("Error inserting user record:", error);
          return;
        }

        const { error: SignUpError } = await supabase.auth.signUp({
          email: email.value,
          password: "test",
        });

        if (SignUpError) {
          console.log(SignUpError);
        }

        /*
        const { error: magicError } =
            await supabase.auth.admin.inviteUserByEmail(form.value.email);

          if (magicError) {
            console.error("Error sending Link:", magicError);
            return;
          }
        
        */

        // Clear the form fields
        message.success("mail sent!");
        clearForm();
        // Send verification email to the user
        // const verificationLink = `${window.location.origin}/verify/${data[0].id}`;
        // console.log(verificationLink);
        // Replace the above line with your custom logic to generate the verification link

        /*const { error: sendEmailError } =
          await supabase.auth.api.sendMagicLinkEmail(
            form.value.email,
            verificationLink
          );

        if (sendEmailError) {
          console.log(verificationLink);
          console.error("Error sending verification email:", sendEmailError);
          return;
        }

        console.log("Verification email sent successfully!");*/
      } catch (error) {
        if (error.errors && error.errors.length > 0) {
          console.error("Form validation error:", error.errors[0]);
        } else {
          console.error("Form validation error:", error);
        }
      }
    }

    function validateGmailAccount(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }

      const domain = value.split("@")[1];
      if (domain !== "gmail.com") {
        callback(new Error("Please enter a Gmail account email."));
      } else {
        callback();
      }
    }

    function clearForm() {
      form.value.email = "";
      //signupForm.value.resetFields();
    }

    return {
      form,
      signupRules,
      signup,
      signupForm,
      clearForm,
    };
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 1rem;
}
</style>
